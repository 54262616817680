import { styled } from "@stitches/react";
import React, { FC } from "react";
import { Header, Text, Wrapper } from "./global";

type RSVPProps = {
  id: string;
};

const RSVP: FC<RSVPProps> = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Header>We hope you can join us!</Header>
      <Text>This is where you can RSVP</Text>
    </Wrapper>
  );
};

export default RSVP;
