import React, { useEffect, useState, useRef } from "react";
import { styled } from "@stitches/react";

import Banner from "./Banner";
import NavBar from "./NavBar";
import Story from "./Story";
import Location from "./Location";
import Schedule from "./Schedule";

import Divider from "./Divider";
import { Wrapper } from "./global";
import RSVP from "./RSVP";
import Names from "./Names";
import Details from "./Details";
import useIsMobile from "./useIsMobile";

const Body = styled("div", {});

const Content = styled("div", {
  fontFamily: "$sans",
  paddingLeft: "$150",
  paddingRight: "$150"
});

const Date = styled("div", {
  display: "block",
  fontSize: "$200",
  fontFamily: "$sans",
  textAlign: "center",
  color: "gray",
  verticalAlign: "3px",
  "@media(max-width: 600px)": {
    fontSize: "$150"
  }
});

function App() {
  const [ready, toggleReady] = useState<boolean>(false);

  const imageRef = useRef(null);
  const windowWidth = useIsMobile();
  const isMobile = windowWidth <= 700;

  useEffect(() => {
    imageRef.current && toggleReady(() => true);
  }, []);

  return (
    <Body
      style={{
        opacity: ready ? 1 : 0,
        transition: "opacity 1s ease"
      }}
    >
      <div
        style={{
          position: "relative"
        }}
      >
        {/* @ts-ignore */}
        <Banner ref={imageRef} isMobile={isMobile} />
        <Names />
      </div>

      <Content>
        <Wrapper
          style={{
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid #ccc",
            borderBottom: "1px solid #ccc",
            padding: "1em",
            maxWidth: "42rem"
          }}
        >
          <Date>Feb. 16, 2025</Date>
        </Wrapper>
        <Wrapper>
          <NavBar />
        </Wrapper>
        <Details id="details" />
        <Divider width={125} icon={"locket"} />
        <Location id="location" />
        <Divider width={75} icon={"shell"} />
        <Schedule id="schedule" />
        <Divider width={125} icon={"disco"} />
      </Content>
    </Body>
  );
}

export { App };

// <Divider width={200} icon={"car"} />
// <Story id="story" />
// <Divider width={200} icon={"hands"} />
// <RSVP id="rsvp" />
