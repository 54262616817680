import React from "react";
import { Block, Header, Text } from "./global";
import { styled } from "./styles";

const StoryContainer = styled(Block, {
  marginTop: "$200",
});

const Story = ({ id }: { id: string }) => {
  return (
    <StoryContainer id={id}>
      <Header>Our story</Header>
      <Text>Chris and Kelcee's story goes here.</Text>
      <Text>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
        hendrerit tempor tellus. Donec pretium posuere tellus. Proin quam nisl,
        tincidunt et, mattis eget, convallis nec, purus. Cum sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla
        posuere. Donec vitae dolor. Nullam tristique diam non turpis. Cras
        placerat accumsan nulla. Nullam rutrum. Nam vestibulum accumsan nisl.
      </Text>
      <Text>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
        hendrerit tempor tellus. Donec pretium posuere tellus. Proin quam nisl,
        tincidunt et, mattis eget, convallis nec, purus. Cum sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla
        posuere. Donec vitae dolor. Nullam tristique diam non turpis. Cras
        placerat accumsan nulla. Nullam rutrum. Nam vestibulum accumsan nisl.
      </Text>
    </StoryContainer>
  );
};

export default Story;
