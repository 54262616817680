import React from "react";
import { styled } from "@stitches/react";

import { Wrapper, Text } from "./global";

const Body = styled("div", {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  "@media(max-width: 500px)": {
    height: "initial",
    marginTop: "1.5em"
  }
});

const Content = styled("div", {
  fontFamily: "$sans",
  paddingLeft: "$150",
  paddingRight: "$150",
  maxWidth: "400px"
});

function App() {
  return (
    <Body>
      <Wrapper>
        <Content>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px auto"
            }}
          >
            <img
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "400px"
              }}
              src="./img/banner.jpg"
            />
          </div>

          <Text>
            Thank you, everyone, for making our wedding one of the happiest days
            of our lives. If you would like to contribute to our registry, you
            can do so{" "}
            <a href="https://honeyfund.com/site/Alcantara2025">
              <b>here</b>
            </a>
            .
          </Text>
        </Content>
      </Wrapper>
    </Body>
  );
}

export { App };
