import { styled } from "@stitches/react";
import React, { FC } from "react";
import { Wrapper, Header, SubHeader, Text, List, Item } from "./global";

type LocationProps = {
  id: string;
};

const Location: FC<LocationProps> = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Header>Getting there</Header>
      <SubHeader>Flights</SubHeader>
      <Text>Guests traveling from out of state can fly into two airports:</Text>
      <List>
        <Item>Jacksonville International Airport</Item>
        <Item>Orlando International Airport</Item>
      </List>

      <SubHeader>Accommodations</SubHeader>
      <Text>
        We've reserved several hotel blocks at the{" "}
        <a href="https://www.marriott.com/en-us/hotels/jaxbr-world-golf-village-renaissance-st-augustine-resort/overview/">
          World Golf Renaissance
        </a>{" "}
        at World Golf Village and the{" "}
        <a href="https://www.holidayinnstaugustine.com/">
          Holiday Inn St. Augustine.
        </a>
      </Text>

      <Text>
        These hotels are conveniently located near a Buc-ees and a Costco.
      </Text>

      <SubHeader>Transportation</SubHeader>

      <Text>
        We <b>strongly encourage</b> guests to rent a vehicle for traveling in
        St. Augustine.
      </Text>

      <Text>
        We will provide a bus to shuttle guests between World Golf Renaissance
        and The Clay Theatre before the ceremony and after the reception.
      </Text>
      <Text>
        If you decide to drive yourself, there will be parking available at the
        venue.
      </Text>
    </Wrapper>
  );
};

export default Location;
