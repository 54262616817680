import { useState, useEffect } from "react";

export default function useIsMobile() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("load", () => {
      setScreenSize(() => window.innerWidth);
    });

    window.addEventListener("resize", () => {
      setScreenSize(() => window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenSize(() => window.innerWidth);
      });
    };
  }, []);
  return screenSize;
}
