import React, { FC } from "react";
import { styled } from "./styles";
import { Wrapper } from "./global";

const NameWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  margin: "$200 auto",
  "@media(max-width: 800px)": {
    flexDirection: "column",
    textAlign: "center",
  },
});

const Name = styled("span", {
  fontSize: "9rem",
  fontWeight: "normal",
  "@media(max-width: 800px)": {
    fontSize: "7rem",
  },
  "@media(max-width: 600px)": {
    fontSize: "5rem",
  },
});

const Ampersand = styled("span", {
  fontWeight: "bold",
  fontSize: "6em",
  paddingTop: "0.6em",
  "@media(max-width: 800px)": {
    paddingTop: "0.3em",
  },
  "@media(max-width: 600px)": {
    fontSize: "3em",
    paddingTop: "0.25em",
  },
});

type NamesProps = {};

const Names: FC<NamesProps> = (props) => {
  return (
    <Wrapper
      style={{
        lineHeight: 1,
      }}
    >
      <NameWrapper className="jasmine">
        <Name>Chris</Name>
        <div
          style={{
            margin: "0 2em",
            display: "flex",
          }}
        >
          <Ampersand className="cylburn">&</Ampersand>
        </div>
        <Name>Kelcee</Name>
      </NameWrapper>
    </Wrapper>
  );
};

export default Names;
