import React, { FC } from "react";
import { styled } from "./styles";

const NameWrapper = styled("div", {
  background: "rgba(0,0,0,0.4)",
  maxWidth: "600px",
  height: "auto",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  "@media(max-width: 700px)": {
    maxWidth: "500px",
  },
  "@media(max-width: 600px)": {
    width: "100%",
  },
});

const Name = styled("span", {
  color: "white",
  fontSize: "6rem",
  fontWeight: "normal",
  "@media(max-width: 900px)": {
    fontSize: "6rem",
  },
  "@media(max-width: 700px)": {
    fontSize: "5rem",
  },

  "@media(max-width: 500px)": {
    fontSize: "4rem",
  },
  "@media(max-width: 400px)": {
    fontSize: "3rem",
  },
});

const Ampersand = styled("span", {
  color: "white",
  fontWeight: "bold",
  fontSize: "4em",
  paddingTop: "0.75em",
  "@media(max-width: 700px)": {
    fontSize: "3em",
    paddingTop: "0.75em",
  },
  "@media(max-width: 600px)": {
    fontSize: "3em",
    paddingTop: "0.75em",
  },

  "@media(max-width: 400px)": {
    fontSize: "2em",
    paddingTop: "0.75em",
  },
});

type NamesProps = {};

const Names: FC<NamesProps> = (props) => {
  return (
    <NameWrapper
      css={{
        position: "absolute",
        top: "10%",
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        padding: "1em 0 ",
      }}
      className="jasmine"
    >
      <Name>Chris</Name>
      <div
        style={{
          margin: "0 1em",
          display: "flex",
        }}
      >
        <Ampersand className="cylburn">&</Ampersand>
      </div>
      <Name>Kelcee</Name>
    </NameWrapper>
  );
};

export default Names;
