import React, { FC } from "react";
import { styled } from "./styles";

type DividerProps = {
  icon: string;
  width: number;
};

const Container = styled("div", {
  textAlign: "center",
  margin: "2em auto",
});

const Divider: FC<DividerProps> = ({ icon, width }) => {
  return (
    <Container
      css={{
        width: `${width}px`,
        height: "auto",
        "@media(max-width: 600px)": {
          width: `${width * 0.75}px`,
        },
      }}
    >
      <img src={`./img/icons/${icon}.svg`} />
    </Container>
  );
};

export default Divider;
