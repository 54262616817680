export const base = "16px";
export const light = {
  alpha0: "rgba(0, 0, 0, .50)",
  alpha20: "rgba(0, 0, 0, .45)",
  alpha40: "rgba(0, 0, 0, .40)",
  alpha60: "rgba(0, 0, 0, .35)",
  alpha80: "rgba(0, 0, 0, .30)",
  alpha100: "rgba(0, 0, 0, .25)",
  alpha200: "rgba(0, 0, 0, .20)",
  alpha300: "rgba(0, 0, 0, .15)",
  alpha400: "rgba(0, 0, 0, .10)",
  alpha500: "rgba(0, 0, 0, .05)",
  alpha50: "rgba(0, 0, 0, .50)",
  alpha25: "rgba(0, 0, 0, .05)",
  red600: "rgba(253, 231, 231, 1)",
  red500: "rgba(249, 221, 219, 1)",
  red400: "rgba(242, 180, 171, 1)",
  red300: "rgba(229, 138, 124, 1)",
  red200: "rgba(211, 95, 79, 1)",
  red100: "rgba(234, 0, 23, 1)",
  red80: "rgba(190, 44, 37, 1)",
  red60: "rgba(142, 31, 27, 1)",
  red40: "rgba(99, 16, 14, 1)",
  spark600: "rgba(255, 246, 241, 1)",
  spark500: "rgba(255, 222, 204, 1)",
  spark400: "rgba(255, 191, 173, 1)",
  spark300: "rgba(254, 140, 108, 1)",
  spark200: "rgba(254, 114, 76, 1)",
  spark100: "rgba(254, 78, 29, 1)",
  spark80: "rgba(219, 48, 1, 1)",
  spark60: "rgba(179, 40, 1, 1)",
  spark40: "rgba(80, 18, 0, 1)",
  orange600: "rgba(255, 239, 224, 1)",
  orange500: "rgba(251, 225, 195, 1)",
  orange400: "rgba(249, 194, 150, 1)",
  orange300: "rgba(241, 162, 106, 1)",
  orange200: "rgba(230, 130, 61, 1)",
  orange100: "rgba(243, 117, 14, 1)",
  orange80: "rgba(216, 97, 0, 1)",
  orange60: "rgba(166, 74, 0, 1)",
  orange40: "rgba(118, 53, 2, 1)",
  mustard600: "rgba(251, 241, 221, 1)",
  mustard500: "rgba(254, 227, 156, 1)",
  mustard400: "rgba(247, 202, 111, 1)",
  mustard300: "rgba(235, 179, 71, 1)",
  mustard200: "rgba(217, 158, 32, 1)",
  mustard100: "rgba(226, 163, 31, 1)",
  mustard80: "rgba(193, 139, 0, 1)",
  mustard60: "rgba(145, 104, 2, 1)",
  mustard40: "rgba(100, 72, 1, 1)",
  gold600: "rgba(250, 240, 227, 1)",
  gold500: "rgba(243, 228, 205, 1)",
  gold400: "rgba(232, 203, 164, 1)",
  gold300: "rgba(222, 181, 126, 1)",
  gold200: "rgba(211, 158, 76, 1)",
  gold100: "rgba(229, 160, 56, 1)",
  gold80: "rgba(194, 136, 47, 1)",
  gold60: "rgba(158, 97, 5, 1)",
  gold40: "rgba(109, 69, 8, 1)",
  yellow600: "rgba(253, 250, 219, 1)",
  yellow100: "rgba(255, 236, 68, 1)",
  green600: "rgba(238, 249, 234, 1)",
  green500: "rgba(213, 237, 202, 1)",
  green400: "rgba(178, 214, 160, 1)",
  green300: "rgba(145, 192, 120, 1)",
  green200: "rgba(110, 169, 81, 1)",
  green100: "rgba(54, 167, 92, 1)",
  green80: "rgba(73, 147, 39, 1)",
  green60: "rgba(56, 113, 30, 1)",
  green40: "rgba(40, 81, 21, 1)",
  teal600: "rgba(234, 251, 254, 1)",
  teal500: "rgba(199, 234, 239, 1)",
  teal400: "rgba(159, 203, 211, 1)",
  teal300: "rgba(118, 172, 182, 1)",
  teal200: "rgba(74, 142, 155, 1)",
  teal100: "rgba(3, 175, 202, 1)",
  teal80: "rgba(15, 113, 128, 1)",
  teal60: "rgba(15, 84, 95, 1)",
  teal40: "rgba(11, 57, 64, 1)",
  blue600: "rgba(231, 240, 254, 1)",
  blue500: "rgba(211, 231, 250, 1)",
  blue400: "rgba(172, 197, 231, 1)",
  blue300: "rgba(132, 164, 214, 1)",
  blue200: "rgba(87, 132, 197, 1)",
  blue100: "rgba(22, 109, 252, 1)",
  blue80: "rgba(19, 102, 179, 1)",
  blue60: "rgba(15, 75, 132, 1)",
  blue40: "rgba(10, 50, 88, 1)",
  purple600: "rgba(250, 238, 249, 1)",
  purple500: "rgba(248, 221, 244, 1)",
  purple400: "rgba(234, 179, 223, 1)",
  purple300: "rgba(217, 137, 201, 1)",
  purple200: "rgba(201, 92, 182, 1)",
  purple100: "rgba(209, 56, 191, 1)",
  purple80: "rgba(182, 35, 161, 1)",
  purple60: "rgba(136, 27, 121, 1)",
  purple40: "rgba(93, 18, 84, 1)",
  pink600: "rgba(252, 233, 239, 1)",
  pink500: "rgba(249, 222, 231, 1)",
  pink400: "rgba(239, 180, 191, 1)",
  pink300: "rgba(228, 138, 155, 1)",
  pink200: "rgba(213, 94, 119, 1)",
  pink100: "rgba(255, 79, 131, 1)",
  pink80: "rgba(195, 39, 85, 1)",
  pink60: "rgba(149, 25, 64, 1)",
  pink40: "rgba(105, 11, 44, 1)",
  gray700: "rgba(255, 255, 255, 1)",
  gray600: "rgba(247, 247, 247, 1)",
  gray500: "rgba(240, 240, 240, 1)",
  gray400: "rgba(233, 233, 233, 1)",
  gray300: "rgba(212, 212, 212, 1)",
  gray200: "rgba(170, 170, 170, 1)",
  gray100: "rgba(115, 115, 115, 1)",
  gray80: "rgba(89, 89, 89, 1)",
  gray60: "rgba(73, 73, 73, 1)",
  gray40: "rgba(42, 42, 42, 1)",
  gray20: "rgba(17, 17, 17, 1)",
  gray0: "rgba(0, 0, 0, 1)",
};
export const dark = {
  alpha0: "rgba(255, 255, 255, .50)",
  alpha20: "rgba(255, 255, 255, .45)",
  alpha40: "rgba(255, 255, 255, .40)",
  alpha60: "rgba(255, 255, 255, .35)",
  alpha80: "rgba(255, 255, 255, .30)",
  alpha100: "rgba(255, 255, 255, .25)",
  alpha200: "rgba(255, 255, 255, .20)",
  alpha300: "rgba(255, 255, 255, .15)",
  alpha400: "rgba(255, 255, 255, .10)",
  alpha500: "rgba(255, 255, 255, .05)",
  alpha50: "rgba(255, 255, 255, .20)",
  alpha25: "rgba(255, 255, 255, .05)",
  red600: "rgba(38, 31, 31, 1)",
  red500: "rgba(45, 39, 39, 1)",
  red400: "rgba(77, 58, 55, 1)",
  red300: "rgba(120, 72, 65, 1)",
  red200: "rgba(184, 83, 69, 1)",
  red100: "rgba(255, 54, 54, 1)",
  red80: "rgba(212, 114, 109, 1)",
  red60: "rgba(210, 165, 164, 1)",
  red40: "rgba(225, 208, 208, 1)",
  spark600: "rgba(43, 30, 26, 1)",
  spark500: "rgba(51, 39, 36, 1)",
  spark400: "rgba(77, 49, 42, 1)",
  spark300: "rgba(115, 68, 56, 1)",
  spark200: "rgba(153, 66, 41, 1)",
  spark100: "rgba(237, 74, 28, 1)",
  spark80: "rgba(178, 120, 104, 1)",
  spark60: "rgba(204, 175, 159, 1)",
  spark40: "rgba(229, 221, 217, 1)",
  orange600: "rgba(36, 31, 27, 1)",
  orange500: "rgba(43, 39, 34, 1)",
  orange400: "rgba(68, 53, 41, 1)",
  orange300: "rgba(97, 65, 43, 1)",
  orange200: "rgba(133, 75, 35, 1)",
  orange100: "rgba(150, 76, 15, 1)",
  orange80: "rgba(179, 81, 0, 1)",
  orange60: "rgba(192, 127, 74, 1)",
  orange40: "rgba(200, 174, 154, 1)",
  mustard600: "rgba(33, 32, 30, 1)",
  mustard500: "rgba(44, 39, 27, 1)",
  mustard400: "rgba(64, 52, 29, 1)",
  mustard300: "rgba(83, 64, 25, 1)",
  mustard200: "rgba(104, 75, 15, 1)",
  mustard100: "rgba(102, 76, 6, 1)",
  mustard80: "rgba(124, 90, 0, 1)",
  mustard60: "rgba(161, 125, 37, 1)",
  mustard40: "rgba(184, 171, 139, 1)",
  gold600: "rgba(31, 30, 28, 1)",
  gold500: "rgba(42, 39, 35, 1)",
  gold400: "rgba(60, 53, 43, 1)",
  gold300: "rgba(79, 65, 45, 1)",
  gold200: "rgba(102, 77, 37, 1)",
  gold100: "rgba(105, 70, 19, 1)",
  gold80: "rgba(128, 89, 31, 1)",
  gold60: "rgba(172, 121, 42, 1)",
  gold40: "rgba(187, 168, 140, 1)",
  yellow600: "rgba(27, 26, 23, 1)",
  yellow100: "rgba(40, 37, 11, 1)",
  green600: "rgba(30, 33, 30, 1)",
  green500: "rgba(36, 40, 34, 1)",
  green400: "rgba(49, 59, 44, 1)",
  green300: "rgba(59, 78, 48, 1)",
  green200: "rgba(65, 101, 48, 1)",
  green100: "rgba(34, 112, 60, 1)",
  green80: "rgba(62, 125, 33, 1)",
  green60: "rgba(113, 153, 94, 1)",
  green40: "rgba(168, 184, 160, 1)",
  teal600: "rgba(29, 33, 33, 1)",
  teal500: "rgba(36, 42, 43, 1)",
  teal400: "rgba(50, 64, 67, 1)",
  teal300: "rgba(61, 89, 94, 1)",
  teal200: "rgba(61, 118, 129, 1)",
  teal100: "rgba(2, 106, 122, 1)",
  teal80: "rgba(78, 150, 162, 1)",
  teal60: "rgba(148, 178, 183, 1)",
  teal40: "rgba(200, 210, 212, 1)",
  blue600: "rgba(31, 34, 38, 1)",
  blue500: "rgba(37, 41, 44, 1)",
  blue400: "rgba(55, 63, 73, 1)",
  blue300: "rgba(69, 86, 112, 1)",
  blue200: "rgba(74, 112, 168, 1)",
  blue100: "rgba(22, 109, 252, 1)",
  blue80: "rgba(88, 146, 201, 1)",
  blue60: "rgba(154, 179, 203, 1)",
  blue40: "rgba(205, 213, 221, 1)",
  purple600: "rgba(33, 29, 33, 1)",
  purple500: "rgba(43, 38, 42, 1)",
  purple400: "rgba(74, 56, 70, 1)",
  purple300: "rgba(112, 71, 103, 1)",
  purple200: "rgba(165, 75, 149, 1)",
  purple100: "rgba(198, 53, 181, 1)",
  purple80: "rgba(204, 100, 189, 1)",
  purple60: "rgba(204, 157, 198, 1)",
  purple40: "rgba(220, 204, 218, 1)",
  pink600: "rgba(36, 32, 33, 1)",
  pink500: "rgba(44, 39, 41, 1)",
  pink400: "rgba(76, 57, 61, 1)",
  pink300: "rgba(117, 71, 79, 1)",
  pink200: "rgba(175, 77, 98, 1)",
  pink100: "rgba(173, 54, 89, 1)",
  pink80: "rgba(213, 103, 136, 1)",
  pink60: "rgba(210, 157, 174, 1)",
  pink40: "rgba(223, 203, 210, 1)",
  gray700: "rgba(20, 20, 20, 1)",
  gray600: "rgba(26, 26, 26, 1)",
  gray500: "rgba(32, 32, 32, 1)",
  gray400: "rgba(37, 37, 37, 1)",
  gray300: "rgba(51, 51, 51, 1)",
  gray200: "rgba(79, 79, 79, 1)",
  gray100: "rgba(127, 127, 127, 1)",
  gray80: "rgba(161, 161, 161, 1)",
  gray60: "rgba(177, 177, 177, 1)",
  gray40: "rgba(222, 222, 222, 1)",
  gray20: "rgba(242, 242, 242, 1)",
  gray0: "rgba(255, 255, 255, 1)",
};
export const staticColors = {
  "alpha0-static": "rgba(0, 0, 0, .65)",
  "alpha20-static": "rgba(0, 0, 0, .45)",
  "alpha40-static": "rgba(0, 0, 0, .40)",
  "alpha60-static": "rgba(0, 0, 0, .35)",
  "alpha80-static": "rgba(0, 0, 0, .30)",
  "alpha100-static": "rgba(0, 0, 0, .25)",
  "alpha200-static": "rgba(0, 0, 0, .20)",
  "alpha300-static": "rgba(0, 0, 0, .15)",
  "alpha400-static": "rgba(0, 0, 0, .10)",
  "alpha500-static": "rgba(0, 0, 0, .05)",
  "alpha50-static": "rgba(0, 0, 0, .50)",
  "alpha25-static": "rgba(0, 0, 0, .05)",
  "red600-static": "rgba(253, 231, 231, 1)",
  "red500-static": "rgba(249, 221, 219, 1)",
  "red400-static": "rgba(242, 180, 171, 1)",
  "red300-static": "rgba(229, 138, 124, 1)",
  "red200-static": "rgba(211, 95, 79, 1)",
  "red100-static": "rgba(234, 0, 23, 1)",
  "red80-static": "rgba(190, 44, 37, 1)",
  "red60-static": "rgba(142, 31, 27, 1)",
  "red40-static": "rgba(99, 16, 14, 1)",
  "spark600-static": "rgba(255, 246, 241, 1)",
  "spark500-static": "rgba(255, 222, 204, 1)",
  "spark400-static": "rgba(255, 191, 173, 1)",
  "spark300-static": "rgba(254, 140, 108, 1)",
  "spark200-static": "rgba(254, 114, 76, 1)",
  "spark100-static": "rgba(254, 78, 29, 1)",
  "spark80-static": "rgba(219, 48, 1, 1)",
  "spark60-static": "rgba(179, 40, 1, 1)",
  "spark40-static": "rgba(80, 18, 0, 1)",
  "orange600-static": "rgba(255, 239, 224, 1)",
  "orange500-static": "rgba(251, 225, 195, 1)",
  "orange400-static": "rgba(249, 194, 150, 1)",
  "orange300-static": "rgba(241, 162, 106, 1)",
  "orange200-static": "rgba(230, 130, 61, 1)",
  "orange100-static": "rgba(243, 117, 14, 1)",
  "orange80-static": "rgba(216, 97, 0, 1)",
  "orange60-static": "rgba(166, 74, 0, 1)",
  "orange40-static": "rgba(118, 53, 2, 1)",
  "mustard600-static": "rgba(251, 241, 221, 1)",
  "mustard500-static": "rgba(254, 227, 156, 1)",
  "mustard400-static": "rgba(247, 202, 111, 1)",
  "mustard300-static": "rgba(235, 179, 71, 1)",
  "mustard200-static": "rgba(217, 158, 32, 1)",
  "mustard100-static": "rgba(226, 163, 31, 1)",
  "mustard80-static": "rgba(193, 139, 0, 1)",
  "mustard60-static": "rgba(145, 104, 2, 1)",
  "mustard40-static": "rgba(100, 72, 1, 1)",
  "gold600-static": "rgba(250, 240, 227, 1)",
  "gold500-static": "rgba(243, 228, 205, 1)",
  "gold400-static": "rgba(232, 203, 164, 1)",
  "gold300-static": "rgba(222, 181, 126, 1)",
  "gold200-static": "rgba(211, 158, 76, 1)",
  "gold100-static": "rgba(229, 160, 56, 1)",
  "gold80-static": "rgba(194, 136, 47, 1)",
  "gold60-static": "rgba(158, 97, 5, 1)",
  "gold40-static": "rgba(109, 69, 8, 1)",
  "yellow600-static": "rgba(253, 250, 219, 1)",
  "yellow100-static": "rgba(255, 236, 68, 1)",
  "green600-static": "rgba(238, 249, 234, 1)",
  "green500-static": "rgba(213, 237, 202, 1)",
  "green400-static": "rgba(178, 214, 160, 1)",
  "green300-static": "rgba(145, 192, 120, 1)",
  "green200-static": "rgba(110, 169, 81, 1)",
  "green100-static": "rgba(54, 167, 92, 1)",
  "green80-static": "rgba(73, 147, 39, 1)",
  "green60-static": "rgba(56, 113, 30, 1)",
  "green40-static": "rgba(40, 81, 21, 1)",
  "teal600-static": "rgba(234, 251, 254, 1)",
  "teal500-static": "rgba(199, 234, 239, 1)",
  "teal400-static": "rgba(159, 203, 211, 1)",
  "teal300-static": "rgba(118, 172, 182, 1)",
  "teal200-static": "rgba(74, 142, 155, 1)",
  "teal100-static": "rgba(3, 175, 202, 1)",
  "teal80-static": "rgba(15, 113, 128, 1)",
  "teal60-static": "rgba(15, 84, 95, 1)",
  "teal40-static": "rgba(11, 57, 64, 1)",
  "blue600-static": "rgba(231, 240, 254, 1)",
  "blue500-static": "rgba(211, 231, 250, 1)",
  "blue400-static": "rgba(172, 197, 231, 1)",
  "blue300-static": "rgba(132, 164, 214, 1)",
  "blue200-static": "rgba(87, 132, 197, 1)",
  "blue100-static": "rgba(22, 109, 252, 1)",
  "blue80-static": "rgba(19, 102, 179, 1)",
  "blue60-static": "rgba(15, 75, 132, 1)",
  "blue40-static": "rgba(10, 50, 88, 1)",
  "purple600-static": "rgba(250, 238, 249, 1)",
  "purple500-static": "rgba(248, 221, 244, 1)",
  "purple400-static": "rgba(234, 179, 223, 1)",
  "purple300-static": "rgba(217, 137, 201, 1)",
  "purple200-static": "rgba(201, 92, 182, 1)",
  "purple100-static": "rgba(209, 56, 191, 1)",
  "purple80-static": "rgba(182, 35, 161, 1)",
  "purple60-static": "rgba(136, 27, 121, 1)",
  "purple40-static": "rgba(93, 18, 84, 1)",
  "pink600-static": "rgba(252, 233, 239, 1)",
  "pink500-static": "rgba(249, 222, 231, 1)",
  "pink400-static": "rgba(239, 180, 191, 1)",
  "pink300-static": "rgba(228, 138, 155, 1)",
  "pink200-static": "rgba(213, 94, 119, 1)",
  "pink100-static": "rgba(255, 79, 131, 1)",
  "pink80-static": "rgba(195, 39, 85, 1)",
  "pink60-static": "rgba(149, 25, 64, 1)",
  "pink40-static": "rgba(105, 11, 44, 1)",
  "gray700-static": "rgba(255, 255, 255, 1)",
  "gray600-static": "rgba(247, 247, 247, 1)",
  "gray500-static": "rgba(240, 240, 240, 1)",
  "gray400-static": "rgba(233, 233, 233, 1)",
  "gray300-static": "rgba(212, 212, 212, 1)",
  "gray200-static": "rgba(170, 170, 170, 1)",
  "gray100-static": "rgba(115, 115, 115, 1)",
  "gray80-static": "rgba(89, 89, 89, 1)",
  "gray60-static": "rgba(73, 73, 73, 1)",
  "gray40-static": "rgba(42, 42, 42, 1)",
  "gray20-static": "rgba(17, 17, 17, 1)",
  "gray0-static": "rgba(0, 0, 0, 1)",
};
export const defaultTheme = {
  errorContainer: "$red600",
  error: "$red200",
  warningContainer: "$orange600",
  warning: "$orange200",
  successContainer: "$green600",
  success: "$green80",
  signalContainer: "$blue600",
  signal: "$blue200",
  "onMessage-subtle-hover": "$gray80",
  "onMessage-subtle": "$gray60",
  "onMessage-hover": "$gray80",
  onMessage: "$gray20",
  portal: "$gray700",
  "onPortal-subtle-hover": "$gray100",
  "onPortal-subtle": "$gray80",
  "onPortal-hover": "$gray100",
  onPortal: "$gray20",
  "onSurface-subtle-hover": "$gray100",
  "onSurface-subtle": "$gray80",
  "onSurface-hover": "$gray100",
  onSurface: "$gray20",
  "surface-highest": "$gray700",
  surface: "$gray700",
  "onBackground-subtle-hover": "$gray100",
  "onBackground-subtle": "$gray80",
  "onBackground-hover": "$gray100",
  onBackground: "$gray20",
  "background-forSurfaces": "$gray600",
  background: "$gray700",
  accessible: "$gray80",
  "outline-subtle": "$alpha500",
  outline: "$alpha400",
  subtle: "$gray300",
  faint: "$gray400",
  onDisabled: "$alpha80",
  disabled: "$alpha500",
  "onCtaContainer-subtle-hover": "$gray80",
  "onCtaContainer-subtle": "$gray60",
  "onCtaContainer-hover": "$gray80",
  onCtaContainer: "$gray20",
  ctaContainer: "$blue600",
  "onCta-hover": "$gray600-static",
  onCta: "$gray700-static",
  "cta-hover": "$blue80",
  cta: "$blue100",
  "onSecondary-subtle-hover": "$gray100",
  "onSecondary-subtle": "$gray80",
  "onSecondary-hover": "$gray100",
  onSecondary: "$gray20",
  "secondary-hover": "$gray500",
  secondary: "$gray700",
  "onPrimary-subtle-hover": "$gray200",
  "onPrimary-subtle": "$gray300",
  "onPrimary-hover": "$gray200",
  onPrimary: "$gray700",
  "primary-hover": "$gray40",
  primary: "$gray20",
  opinions: "$spark100",
  "opinions-hover": "$spark80",
  onOpinions: "$gray20-static",
  "onOpinions-hover": "$alpha0-static",
  opinionsContainer: "$spark500",
  onOpinionsContainer: "$gray20",
  "onOpinionsContainer-hover": "$alpha0",
};
export const darkTheme = {
  errorContainer: "$red600",
  error: "$red200",
  warningContainer: "$orange600",
  warning: "$orange200",
  successContainer: "$green600",
  success: "$green80",
  signalContainer: "$blue600",
  signal: "$blue200",
  "onMessage-subtle-hover": "$gray80",
  "onMessage-subtle": "$gray60",
  "onMessage-hover": "$gray80",
  onMessage: "$gray20",
  portal: "$gray200",
  "onPortal-subtle-hover": "$gray40",
  "onPortal-subtle": "$gray20",
  "onPortal-hover": "$gray40",
  onPortal: "$gray0",
  "onSurface-subtle-hover": "$gray80",
  "onSurface-subtle": "$gray60",
  "onSurface-hover": "$gray80",
  onSurface: "$gray0",
  "surface-highest": "$gray300",
  surface: "$gray500",
  "onBackground-subtle-hover": "$gray100",
  "onBackground-subtle": "$gray80",
  "onBackground-hover": "$gray100",
  onBackground: "$gray0",
  "background-forSurfaces": "$gray700",
  background: "$gray700",
  accessible: "$gray80",
  "outline-subtle": "$alpha500",
  outline: "$alpha400",
  subtle: "$gray300",
  faint: "$gray400",
  onDisabled: "$alpha80",
  disabled: "$alpha500",
  "onCtaContainer-subtle-hover": "$gray80",
  "onCtaContainer-subtle": "$gray60",
  "onCtaContainer-hover": "$gray80",
  onCtaContainer: "$gray0",
  ctaContainer: "$blue600",
  "onCta-hover": "$gray600-static",
  onCta: "$gray700-static",
  "cta-hover": "$blue300",
  cta: "$blue100",
  "onSecondary-subtle-hover": "$gray100",
  "onSecondary-subtle": "$gray80",
  "onSecondary-hover": "$gray100",
  onSecondary: "$gray0",
  "secondary-hover": "$gray500",
  secondary: "$gray700",
  "onPrimary-subtle-hover": "$gray200",
  "onPrimary-subtle": "$gray300",
  "onPrimary-hover": "$gray200",
  onPrimary: "$gray700",
  "primary-hover": "$gray40",
  primary: "$gray0",
  opinions: "$spark100",
  "opinions-hover": "$spark80",
  onOpinions: "$gray20-static",
  "onOpinions-hover": "$alpha0-static",
  opinionsContainer: "$spark500",
  onOpinionsContainer: "$gray20",
  "onOpinionsContainer-hover": "$alpha0",
};
export const sizes = {
  "100": "1rem",
  "125": "1.25rem",
  "150": "1.5rem",
  "175": "1.75rem",
  "200": "2rem",
  "225": "2.25rem",
  "250": "2.5rem",
  "275": "2.75rem",
  "300": "3rem",
  "350": "3.5rem",
  "400": "4rem",
  "450": "4.5rem",
  "500": "5rem",
  "025": "0.25rem",
  "050": "0.5rem",
  "075": "0.75rem",
  "087": "0.875rem",
};
export const spaces = {
  "100": "1rem",
  "125": "1.25rem",
  "150": "1.5rem",
  "175": "1.75rem",
  "200": "2rem",
  "225": "2.25rem",
  "250": "2.5rem",
  "275": "2.75rem",
  "300": "3rem",
  "350": "3.5rem",
  "400": "4rem",
  "450": "4.5rem",
  "500": "5rem",
  "025": "0.25rem",
  "050": "0.5rem",
  "075": "0.75rem",
};
export const radii = {
  "100": "1rem",
  "125": "1.25rem",
  "150": "1.5rem",
  "012": "0.125rem",
  "025": "0.25rem",
  "050": "0.5rem",
  "075": "0.75rem",
  round: "9999px",
};
export const fonts = {
  headline: "Postoni, Postoni-fallback, serif",
  subhead: "Franklin, Franklin-fallback, sans-serif",
  body: "georgia, Times New Roman, serif",
  meta: "Franklin, Franklin-fallback, sans-serif",
  magazine: "PostoniDisplayMag, PostoniDisplayMag-fallback, serif",
};
export const fontSizes = {
  "100": "1rem",
  "112": "1.125rem",
  "125": "1.25rem",
  "150": "1.5rem",
  "162": "1.625rem",
  "175": "1.75rem",
  "200": "2rem",
  "225": "2.25rem",
  "250": "2.5rem",
  "275": "2.75rem",
  "300": "3rem",
  "350": "3.5rem",
  "400": "4rem",
  "450": "4.5rem",
  "500": "5rem",
  "075": "0.75rem",
  "087": "0.875rem",
};
export const fontWeights = { light: 300, regular: 400, bold: 700, ultra: 800 };
export const lineHeights = {
  "100": 1,
  "110": 1.1,
  "125": 1.25,
  "150": 1.5,
  "160": 1.6,
  "175": 1.75,
  "200": 2,
  "240": 2.4,
};
export const shadows = {
  "50": "0px 2px 0px 0px #D5D5D5",
  "100": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
  "200": "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
  "300": "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
  "400": "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
  "500": "0px 16px 32px 0px rgba(0, 0, 0, 0.15)",
};
export const zIndices = { offer: 400, shell: 300, ads: 200, page: 100 };
