import React, { FC } from "react";
import { Header, Text, Wrapper } from "./global";

type DetailsProps = {
  id: string;
};

const Details: FC<DetailsProps> = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Header>The event</Header>

      <Text>
        Chris and Kelcee's wedding festivities will take place from Saturday,
        Feb. 15 to Monday, Feb. 17, 2025 in St. Augustine, Fla. More details can
        be found on our <a href="#schedule">schedule</a>.
      </Text>

      <Text>
        The wedding ceremony is on <b>Sunday, Feb. 16</b> at <b>4 p.m.</b>,
        located at{" "}
        <a href="https://www.google.com/maps/dir//Clay+Theatre,+326+Walnut+St,+Green+Cove+Springs,+FL+32043/@38.9152768,-77.0244608,16z/data=!4m9!4m8!1m1!4e2!1m5!1m1!1s0x88e5d0b21e17349d:0x42ea6ff9ea430616!2m2!1d-81.6791705!2d29.9937623?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D">
          The Clay Theatre
        </a>{" "}
        in Green Cove Springs.
      </Text>

      <Text>
        The wedding dress code is <b>Black Tie Optional</b>. Ladies, TKTK. Men,
        tuxedos are not required—you are free to wear one, though, if you'd
        like!—but please wear a suit.
      </Text>

      <Text>
        While we love your children, this will be an{" "}
        <u>
          <b>adult-only</b>
        </u>{" "}
        event.
      </Text>
    </Wrapper>
  );
};

export default Details;
