import { styled } from "@stitches/react";
import React, { FC } from "react";
import { Wrapper, Header, Text } from "./global";

type Day = {
  day: string;
  events: Event[];
};

type Icon = {
  icon: string;
  size: number;
};
type Event = {
  time: string;
  title: string;
  location: string | null;
  summary: string;
  url?: string;
  icons?: Icon[];
};

const EventWrapper = styled(Wrapper, {
  display: "flex",
  flexDirection: "row"
});

const EventDotLeaderContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  paddingTop: "4px"
});

const EventDot = styled("div", {
  height: "12px",
  width: "12px",
  borderRadius: "100%",
  background: "black"
});
const EventLeader = styled("div", {
  width: "2px",
  height: "100%",
  background: "black",
  margin: "0px auto"
});

const EventDetails = styled("div", {
  paddingLeft: "10px"
});

// const EventLocation = styled("span", {
//   display: "block",
// });

const EventTime = styled("span", {
  display: "block",
  fontSize: "$125",
  fontWeight: "bold",
  "@media(max-width: 600px)": {
    fontSize: "$112"
  }
});

const EventDay = styled("h2", {
  fontSize: "$175",
  marginBottom: "$100",
  "@media(max-width: 600px)": {
    fontSize: "$150"
  }
});

// const EventTitle = styled("h3", {
//   fontSize: "$150",
//   marginTop: "10px",
//   marginBottom: "5px",
// });

const EventSummary = styled(Text, {
  margin: "0em auto 0.5em auto"
});

const EventIcons = ({ icons, idx }) => {
  if (!icons) return <></>;

  return (
    <div
      key={idx}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "end"
      }}
    >
      {icons.map(function ({ icon, size }, i: number) {
        return (
          <img
            key={i}
            style={{
              marginRight: "10px",
              width: `${size}px`,
              marginBottom: "1.5em"
            }}
            src={`./img/icons/${icon}.svg`}
          />
        );
      })}
    </div>
  );
};

const Event = ({ day, events, idx }) => {
  return (
    <div key={idx}>
      <EventDay>{day}</EventDay>
      {events.map(function (event: Event, i: number) {
        const { time, title, location, summary, url, icons } = event;
        const pipe = location ? "|" : null;

        const hotLink = url ? (
          <a key={i} href={url} style={{ color: "black" }}>
            {location}
          </a>
        ) : (
          location
        );

        return (
          <EventWrapper key={i}>
            <EventDotLeaderContainer>
              <EventDot />
              <EventLeader />
            </EventDotLeaderContainer>
            <EventDetails>
              <EventTime>
                {time} | {title} {pipe} {hotLink}
              </EventTime>
              <EventSummary>{summary}</EventSummary>
              <EventIcons key={i} idx={i} icons={icons} />
            </EventDetails>
          </EventWrapper>
        );
      })}
    </div>
  );
};

type ScheduleProps = {
  id: string;
};

const Schedule: FC<ScheduleProps> = ({ id }) => {
  const events: Day[] = [
    {
      day: "Saturday, Feb. 15",
      events: [
        {
          time: "4 p.m.",
          location: "Venue TBA, St. Augustine",
          title: "Welcome Happy Hour",
          summary: "We will host a welcome happy hour in St. Augustine.",
          icons: [
            { icon: "cocktail", size: 40 },
            { icon: "wine", size: 40 }
          ]
        }
      ]
    },
    {
      day: "Sunday, Feb. 16",
      events: [
        {
          time: "5 p.m.",
          location: "The Clay Theatre, Green Cove Springs",
          title: "Ceremony",
          summary: "All guests should arrive by 4:45 p.m. to be seated.",
          icons: [{ icon: "couple", size: 75 }]
        },
        {
          time: "5:30 p.m. to 6:30 p.m.",
          location: null,
          title: "Cocktail hour",
          summary: "Guest can enjoy drinks and hors d'oeuvres.",
          icons: [
            { icon: "cheese", size: 75 },
            { icon: "grapes", size: 50 }
          ]
        },
        {
          time: "6:30 p.m. to 11 p.m.",
          location: null,
          title: "Reception",
          summary:
            "The party begins! Stay tuned for dinner, dancing and speeches.",

          icons: [{ icon: "dinner", size: 100 }]
        },

        {
          time: "11 p.m. to ???",
          location: "Venue TBA",
          title: "After party",
          summary:
            "For night owls, we'll move to a nearby venue for a few more hours of celebrating.",
          icons: [{ icon: "clinking-glass", size: 100 }]
        }
      ]
    },
    {
      day: "Monday, Feb. 17",
      events: [
        {
          time: "11 a.m.",
          location: "World Golf Village Renaissance, St. Augustine",
          title: "Brunch",
          summary: "Join us for brunch at our hotel. All guests are welcome.",
          icons: []
        }
      ]
    }
  ];

  return (
    <Wrapper id={id}>
      <Header>Schedule</Header>
      {events.map(function (day: Day, index: number) {
        return (
          <Event key={index} idx={index} day={day.day} events={day.events} />
        );
      })}
    </Wrapper>
  );
};

export default Schedule;
