import { styled } from "./styles";

export const Block = styled("div", {
  scrollMarginTop: "25px",
});

export const Wrapper = styled(Block, {
  maxWidth: "42rem",
  margin: "0px auto",
});

export const Header = styled("h1", {
  margin: "0px auto",
  maxWidth: "42rem",
  fontSize: "$250",
  fontFamily: "$sans",
  textAlign: "left",
  "@media(max-width: 600px)": {
    fontSize: "$175",
  },
});

export const SubHeader = styled("h3", {
  margin: "0px auto",
  maxWidth: "42rem",
  fontSize: "$175",
  fontFamily: "$sans",
  marginTop: "$150",
  textAlign: "left",
  "@media(max-width: 600px)": {
    fontSize: "$125",
  },
});

export const Text = styled("p", {
  margin: "1em auto",
  maxWidth: "42rem",
  fontSize: "$125",
  lineHeight: 1.5,
  fontFamily: "$sans",
  color: "#000000",
  "@media(max-width: 600px)": {
    fontSize: "$112",
  },
});

export const List = styled("ul", {
  fontSize: "$125",
  "@media(max-width: 600px)": {
    fontSize: "$112",
  },
});

export const Item = styled("li", {
  marginTop: "0.5em",
});
