import React, { FC, forwardRef, MutableRefObject } from "react";
import { styled } from "./styles";

type BannerProps = {
  isMobile: boolean;
};

const Image = styled("img", {
  display: "block",
  width: "100%",
});

const Banner: FC<BannerProps> = forwardRef(function ({ isMobile }, ref) {
  const bannerToUse = isMobile
    ? "./img/banner-cropped.jpg"
    : "./img/banner.jpg";
  // @ts-ignore
  return <Image ref={ref} src={bannerToUse} />;
});

export default Banner;
