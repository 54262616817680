import React from "react";
import { styled } from "./styles";

const NavContainer = styled("div", {
  position: "sticky",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  zIndex: "1",
  fontFamily: "$sans",
  margin: "2em auto",
  "@media(max-width: 600px)": {
    flexWrap: "wrap",
  },
});

const NavLink = styled("a", {
  border: "1px solid black",
  borderRadius: "$025",
  width: "100%",
  textAlign: "center",
  padding: "0.5em",
  margin: "0.25em",
  textDecoration: "none",
  "&:hover": {
    background: "#ccc",
    transition: "background 0.25s ease",
  },
});

const navLinks = [
  { title: "Details", id: "details" },
  { title: "Story", id: "story" },
  { title: "Travel", id: "location" },
  { title: "Schedule", id: "schedule" },
  { title: "RSVP", id: "rsvp" },
];

const NavBar = () => {
  return (
    <NavContainer>
      {navLinks.map(function (link: any, i: number) {
        const { title, id } = link;
        return (
          <NavLink key={i} href={`#${id}`}>
            {title}
          </NavLink>
        );
      })}
    </NavContainer>
  );
};

export default NavBar;
