import { createStitches } from "@stitches/react";

import * as tokens from "./tokens";

const breakpoints = {
  sm: "767px",
  md: "900px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1440px",
};

export const { styled, css } = createStitches({
  theme: {
    colors: {
      bg: "#fff",
      fg: "#000",
      gray: "rgb(100, 100, 100)",
      "light-gray": "rgb(222, 222, 222)",
      "lighter-gray": "rgb(240, 240, 240)",
      "lightest-gray": "rgb(245, 245, 245)",
      "article-color": "#212121",
      "gray-alpha": "rgba(19, 20, 21, 0.5)",
      selection: "rgba(0, 0, 0, 0.99)",
      link: "#3563a4",
    },
    sizes: tokens.sizes,
    space: tokens.spaces,
    radii: tokens.radii,
    fonts: {
      sans: "-apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue,sans-serif",
    },
    fontSizes: tokens.fontSizes,
    fontWeights: tokens.fontWeights,
    shadows: tokens.shadows,
    zIndices: tokens.zIndices,
    lineHeights: {
      ...tokens.lineHeights,
      headline: "$110",
      body: "$160",
      meta: "$125",
      subhead: "$meta",
    },
    transitions: {
      allFast: "all $fast $inOut",
      fast: "0.2s",
      normal: "0.3s",
      inOut: "cubic-bezier(.4, 0, .2, 1)",
    },
    borderStyles: {},
    borderWidths: {},
    letterSpacings: {},
  },
  media: {
    sm: `(max-width: ${breakpoints.sm})`,
    md: `(min-width: calc(${breakpoints.sm} + 1px)) and (max-width: ${breakpoints.md})`,
    lg: `(min-width: calc(${breakpoints.md} + 1px)) and (max-width: ${breakpoints.lg})`,
    xl: `(min-width: calc(${breakpoints.lg} + 1px)) and (max-width: ${breakpoints.xl})`,
    xxl: `(min-width: calc(${breakpoints.xl} + 1px)) and (max-width: ${breakpoints.xxl})`,
    notSm: `(min-width: calc(${breakpoints.sm} + 1px))`,
    notMd: `(min-width: calc(${breakpoints.md} + 1px))`,
    notLg: `(min-width: calc(${breakpoints.lg} + 1px))`,
    notXl: `(min-width: calc(${breakpoints.xl} + 1px))`,
    notXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
    minSm: `(min-width: calc(${breakpoints.sm} + 1px))`,
    minMd: `(min-width: calc(${breakpoints.md} + 1px))`,
    minLg: `(min-width: calc(${breakpoints.lg} + 1px))`,
    minXl: `(min-width: calc(${breakpoints.xl} + 1px))`,
    minXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
    maxSm: `(max-width: ${breakpoints.sm})`,
    maxMd: `(max-width: ${breakpoints.md})`,
    maxLg: `(max-width: ${breakpoints.lg})`,
    maxXl: `(max-width: ${breakpoints.xl})`,
    maxXxl: `(max-width: ${breakpoints.xxl})`,
    reducedMotion: "(prefers-reduced-motion)",
    hover: "(any-hover: hover)",
    dark: "(prefers-color-scheme: dark)",
    light: "(prefers-color-scheme: light)",
  },
});
